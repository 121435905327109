import { Formik, FormikHelpers } from 'formik'
import { useGraphClient } from 'graph/GraphClientContext'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiFieldText, EuiForm, EuiFormRow, EuiComboBoxOptionOption, EuiSelectOption, EuiSpacer, EuiSelect, EuiComboBox, EuiText, EuiFlexGroup, EuiFlexItem, EuiSwitch, EuiBadge, EuiCopy, EuiDatePicker, EuiSuperSelect } from '@elastic/eui'
import { EuiSuperSelectOption } from '@elastic/eui/src/components/form/super_select/super_select_control'

import { Dma, Organization, UpdateOrganization, useOpsClient } from 'api'
import { useGetAccountManagersQuery } from 'api/rtkQueryApi/graphClient/graphClientApi'
import config from 'app/config'
import { AgencyDetailsSection } from 'features/customers/organizations/AgencyDetailsSection'
import { OrganizationPermissionsSection } from 'features/customers/organizations/OrganizationPermissionsSection'

import { useGetOrganizationDefaultTargetingQuery } from '../../../api/rtkQueryApi/opsApi/defaultTargetingApi'
import { useUploadOrganizationToPipedriveMutation } from '../../../api/rtkQueryApi/opsApi/organizationsApi'

interface OrganizationDetailsTabParams {
  organization: Organization

  refetchOrganization(id: string): void
}

interface FormValues {
  organizationName: string
  billingEmail: string
  phone: string | null
  dma: string | null
  isAgency: boolean
  verifiedAgency: boolean
  receivesChecks: boolean
  excludeFromMessageSequence: boolean
  adConsultantId: string | null
  campaignAdvisorId: string | null
  organizationManagerId: string | null
  turns: number
  partnerId: string | null
  allowBankAccountPayments: boolean
  initiatedEmailConversation?: moment.Moment
  initiatedTextConversation?: moment.Moment
  initiatedPhoneConversation?: moment.Moment
}

const OrganizationDetailsTab: React.FC<OrganizationDetailsTabParams> = ({ organization, refetchOrganization }) => {
  const { isLoading: accountManagersLoading, data: accountManagersData } = useGetAccountManagersQuery()
  const [isLoadingDmas, setIsLoadingDmas] = useState(false)
  const [uploadOrganizationPipedrive, uploadOrganizationPipedriveQuery] = useUploadOrganizationToPipedriveMutation()
  const targeting = useGetOrganizationDefaultTargetingQuery({ organizationId: organization.organizationId })
  const [initial, setInitial] = useState<FormValues>({
    organizationName: '',
    billingEmail: '',
    phone: '',
    dma: null,
    isAgency: false,
    verifiedAgency: false,
    receivesChecks: false,
    excludeFromMessageSequence: false,
    adConsultantId: null,
    campaignAdvisorId: null,
    organizationManagerId: null,
    turns: 0,
    partnerId: null,
    allowBankAccountPayments: false
  })
  const [dmas, setDmas] = useState<Dma[]>([])
  const [dmaOptions, setDmaOptions] = useState<EuiComboBoxOptionOption[]>([])
  const [chosenDma, setChosenDma] = useState<EuiComboBoxOptionOption[]>([])
  const opsClient = useOpsClient()
  const graphClient = useGraphClient()
  const [isLoadingCampaignAdvisors, setIsLoadingCampaignAdvisors] = useState(false)
  const [campaignAdvisors, setCampaignAdvisors] = useState<EuiSelectOption[]>([])
  const [accountManagers, setAccountManagers] = useState<EuiSelectOption[]>([])
  const [isLoadingPartners, setIsLoadingPartners] = useState(false)
  const [partners, setPartners] = useState<EuiSelectOption[]>([])
  const [defaultTargeting, setDefaultTargeting] = useState(organization.defaultTargetingId ?? 'none')

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted) {
      setIsLoadingDmas(true)
      setInitial({
        organizationName: organization.organizationName,
        billingEmail: organization.billingEmail,
        phone: organization.phoneNumber,
        dma: organization.dma,
        isAgency: organization.isAgency,
        verifiedAgency: organization.verifiedAgency,
        receivesChecks: organization.receivesChecks,
        excludeFromMessageSequence: organization.excludeFromMessageSequence,
        adConsultantId: organization.adConsultantId,
        campaignAdvisorId: organization.campaignAdvisorId,
        organizationManagerId: organization.organizationManagerId,
        partnerId: organization.partnerId,
        turns: 0,
        allowBankAccountPayments: organization.allowBankAccountPayments,
        initiatedEmailConversation: organization.initiatedEmailConversation ? moment(organization.initiatedEmailConversation) : undefined,
        initiatedTextConversation: organization.initiatedTextConversation ? moment(organization.initiatedTextConversation) : undefined,
        initiatedPhoneConversation: organization.initiatedPhoneConversation ? moment(organization.initiatedPhoneConversation) : undefined
      })
      opsClient.getDmas().then(result => {
        if (isMounted) {
          setDmas(result)
          setIsLoadingDmas(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, organization])

  useEffect(() => {
    let isMounted = true

    if (graphClient && isMounted) {
      setIsLoadingCampaignAdvisors(true)

      graphClient.getAdConsultants().then(a => {
        if (isMounted) {
          let options = a.map(x => ({ value: x.mail, text: x.displayName }))
          options.push({ value: '', text: '[not assigned]' })
        }
      })
      setIsLoadingCampaignAdvisors(true)
      graphClient.getCampaignAdvisors().then(a => {
        if (isMounted) {
          let options = a.map(x => ({ value: x.mail, text: x.displayName }))
          options.push({ value: '', text: '[not assigned]' })
          setCampaignAdvisors(options)
          setIsLoadingCampaignAdvisors(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [graphClient])

  useEffect(() => {
    if (opsClient) {
      setIsLoadingPartners(true)
      opsClient.getPartners().then(a => {
        let options = a.map(x => ({ value: x.partnerId, text: x.name }))
        options.push({ value: '', text: '[n/a - public]' })
        setPartners(options)
        setIsLoadingPartners(false)
      })
    }
  }, [opsClient])

  useEffect(() => {
    if (dmas) {
      const p = dmas.map(
        d =>
          ({
            key: d.name,
            label: d.name
          } as EuiComboBoxOptionOption)
      )
      setDmaOptions(p)
      if (organization && organization.targetingTemplates.includes('greatclips-recruitment')) {
        setChosenDma(p.filter(d => d.key === organization.dma))
      }
    }
  }, [dmas, organization])

  useEffect(() => {
    if (accountManagersLoading || !accountManagersData) {
      return
    }

    const options = accountManagersData.map(a => ({ value: a.mail, text: a.displayName }))
    setAccountManagers(options)
  }, [accountManagersLoading, accountManagersData])

  const organizationSchema = Yup.object().shape({
    organizationName: Yup.string().max(128).required('Please enter the organization name'),
    billingEmail: Yup.string().email('Please enter a valid email').required('Please enter the billing email'),
    phone: Yup.string().nullable(true),
    dma: Yup.string().nullable(true),
    isAgency: Yup.boolean(),
    verifiedAgency: Yup.boolean(),
    receivesChecks: Yup.boolean(),
    excludeFromMessageSequence: Yup.boolean(),
    adConsultantId: Yup.string().nullable(),
    campaignAdvisorId: Yup.string().nullable(),
    organizationManagerId: Yup.string().nullable(),
    partnerId: Yup.string().nullable(),
    turns: Yup.number().min(0)
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const update: UpdateOrganization = {
      organizationName: values.organizationName,
      billingEmail: values.billingEmail,
      phone: values.phone,
      dma: organization!.dma,
      isAgency: values.isAgency,
      verifiedAgency: values.verifiedAgency,
      receivesChecks: values.receivesChecks,
      excludeFromMessageSequence: values.excludeFromMessageSequence,
      adConsultantId: values.adConsultantId === '' ? null : values.adConsultantId,
      campaignAdvisorId: values.campaignAdvisorId === '' ? null : values.campaignAdvisorId,
      organizationManagerId: values.organizationManagerId === '' ? null : values.organizationManagerId,
      partnerId: values.partnerId === '' ? null : values.partnerId,
      allowBankAccountPayments: values.allowBankAccountPayments,
      initiatedEmailConversation: values.initiatedEmailConversation,
      initiatedTextConversation: values.initiatedTextConversation,
      initiatedPhoneConversation: values.initiatedPhoneConversation,
      defaultTargetingId: defaultTargeting === 'none' ? undefined : defaultTargeting
    }

    if (organization!.targetingTemplates.includes('greatclips-recruitment')) {
      update.dma = values.dma
    } else {
      update.dma = null
    }

    opsClient?.updateOrganization(organization.organizationId, update).then(result => {
      setSubmitting(false)
      refetchOrganization(result.organizationId)
    })
  }

  const selectDma = (options: EuiComboBoxOptionOption[]) => {
    setChosenDma(options)
  }

  const onOrganizationUpdated = () => {
    opsClient?.getOrganization(organization.organizationId).then(result => {
      refetchOrganization(result.organizationId)
    })
  }

  const onUploadOrganizationToPipedriveClick = () => {
    uploadOrganizationPipedrive({ organizationId: organization.organizationId })
  }

  const targetingOptions: EuiSuperSelectOption<string>[] = [
    {
      value: 'none',
      inputDisplay: <strong>None</strong>
    }
  ]

  if (targeting.data) {
    targetingOptions.push(
      ...targeting.data.map(targeting => {
        return {
          value: targeting.id,
          inputDisplay: targeting.name
        }
      })
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <Formik initialValues={initial} enableReinitialize validationSchema={organizationSchema} onSubmit={doSubmit}>
        {props => (
          <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
            <EuiFormRow label='Name' isInvalid={!!props.errors.organizationName} error={props.errors.organizationName}>
              <EuiFieldText name='organizationName' value={props.values.organizationName} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.organizationName} />
            </EuiFormRow>
            <EuiFormRow label='Billing Email' isInvalid={!!props.errors.billingEmail} error={props.errors.billingEmail}>
              <EuiFieldText name='billingEmail' value={props.values.billingEmail} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.billingEmail} />
            </EuiFormRow>
            <EuiFormRow label='Phone' isInvalid={!!props.errors.phone} error={props.errors.phone}>
              <EuiFieldText name='phone' value={props.values.phone ?? ''} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.phone} />
            </EuiFormRow>
            <EuiFormRow label='Partner' isInvalid={!!props.errors.partnerId} error={props.errors.partnerId}>
              <EuiSelect name='partnerId' isInvalid={!!props.errors.partnerId} options={partners} isLoading={isLoadingPartners} value={props.values.partnerId ?? ''} onChange={e => props.setFieldValue('partnerId', e.target.value)} />
            </EuiFormRow>
            {organization && <AgencyDetailsSection organizationId={organization.organizationId} onOrganizationUpdated={onOrganizationUpdated} />}
            {organization && organization.targetingTemplates.includes('greatclips-recruitment') && (
              <EuiFormRow label='Targeted DMA (for Great Clips)' isInvalid={chosenDma.length !== 1} error={'Please select a DMA'}>
                <EuiComboBox singleSelection={{ asPlainText: true }} options={dmaOptions} onChange={id => selectDma(id)} selectedOptions={chosenDma} isLoading={isLoadingDmas} />
              </EuiFormRow>
            )}
            {organization && organization.signupSource && (
              <EuiFormRow label='Acid'>
                <EuiText size='s'>{organization.signupSource}</EuiText>
              </EuiFormRow>
            )}
            {organization && organization.signupMethod && (
              <EuiFormRow label='Signup Method'>
                <EuiText size='s'>{organization.signupMethod}</EuiText>
              </EuiFormRow>
            )}
            <EuiFormRow label={'Permissions'}>
              <OrganizationPermissionsSection organizationId={organization.organizationId} />
            </EuiFormRow>
            <EuiSpacer size='m' />
            <EuiSwitch onChange={e => props.setFieldValue('allowBankAccountPayments', e.target.checked)} checked={props.values.allowBankAccountPayments} label='Allow bank account payments?' labelProps={{ style: { whiteSpace: 'nowrap' } }} />
            <EuiSpacer size='m' />
            <EuiSwitch onChange={e => props.setFieldValue('verifiedAgency', e.target.checked)} checked={props.values.verifiedAgency} label='Suspected agency?' labelProps={{ style: { whiteSpace: 'nowrap' } }} />
            <EuiSpacer size='m' />
            <EuiSwitch onChange={e => props.setFieldValue('isAgency', e.target.checked)} checked={props.values.isAgency} label='Enable agency platform?' labelProps={{ style: { whiteSpace: 'nowrap' } }} />
            <EuiSpacer size='m' />
            <EuiSwitch onChange={e => props.setFieldValue('receivesChecks', e.target.checked)} checked={props.values.receivesChecks} label='Receives agency discount?' labelProps={{ style: { whiteSpace: 'nowrap' } }} disabled={true} />
            <EuiSpacer size='m' />
            <EuiSwitch onChange={e => props.setFieldValue('excludeFromMessageSequence', e.target.checked)} checked={props.values.excludeFromMessageSequence} label='Exclude from e-mail sequences?' labelProps={{ style: { whiteSpace: 'nowrap' } }} />
            <EuiSpacer size='m' />
            <EuiFormRow label='Marketing Events'>
              <React.Fragment>
                <EuiSpacer size='m' />
                <EuiSwitch onChange={e => props.setFieldValue('initiatedEmailConversation', e.target.checked ? moment() : undefined)} checked={!!props.values.initiatedEmailConversation} label='Initiated Email Conversation' labelProps={{ style: { whiteSpace: 'nowrap' } }} disabled={!!organization.initiatedEmailConversation} />
                {!!props.values.initiatedEmailConversation && (
                  <React.Fragment>
                    <EuiSpacer size='s' />
                    <EuiDatePicker selected={props.values.initiatedEmailConversation} onChange={date => props.setFieldValue('initiatedEmailConversation', date)} />
                  </React.Fragment>
                )}
                <EuiSpacer size='m' />
                <EuiSwitch onChange={e => props.setFieldValue('initiatedTextConversation', e.target.checked ? moment() : undefined)} checked={!!props.values.initiatedTextConversation} label='Initiated Text Conversation' labelProps={{ style: { whiteSpace: 'nowrap' } }} disabled={!!organization.initiatedTextConversation} />
                {!!props.values.initiatedTextConversation && (
                  <React.Fragment>
                    <EuiSpacer size='s' />
                    <EuiDatePicker selected={props.values.initiatedTextConversation} onChange={date => props.setFieldValue('initiatedTextConversation', date)} />
                  </React.Fragment>
                )}
                <EuiSpacer size='m' />
                <EuiSwitch onChange={e => props.setFieldValue('initiatedPhoneConversation', e.target.checked ? moment() : undefined)} checked={!!props.values.initiatedPhoneConversation} label='Initiated Phone Conversation' labelProps={{ style: { whiteSpace: 'nowrap' } }} disabled={!!organization.initiatedPhoneConversation} />
                {!!props.values.initiatedPhoneConversation && (
                  <React.Fragment>
                    <EuiSpacer size='s' />
                    <EuiDatePicker selected={props.values.initiatedPhoneConversation} onChange={date => props.setFieldValue('initiatedPhoneConversation', date)} />
                  </React.Fragment>
                )}
                <EuiSpacer size='m' />
              </React.Fragment>
            </EuiFormRow>
            <EuiFormRow label='Primary Campaign Advisor' isInvalid={!!props.errors.campaignAdvisorId} error={props.errors.campaignAdvisorId}>
              <EuiSelect name='campaignAdvisorId' isInvalid={!!props.errors.campaignAdvisorId} options={campaignAdvisors} isLoading={isLoadingCampaignAdvisors} value={props.values.campaignAdvisorId ?? ''} onChange={e => props.setFieldValue('campaignAdvisorId', e.target.value)} />
            </EuiFormRow>
            <EuiFormRow label='Account Manager' isInvalid={!!props.errors.organizationManagerId} error={props.errors.organizationManagerId}>
              <EuiSelect name='accountManagerId' isInvalid={!!props.errors.organizationManagerId} options={accountManagers} isLoading={accountManagersLoading} value={props.values.organizationManagerId ?? ''} onChange={e => props.setFieldValue('organizationManagerId', e.target.value)} />
            </EuiFormRow>
            <EuiSpacer />
            <EuiFormRow label='Default Targeting'>
              <EuiFlexItem>
                <EuiSuperSelect options={targetingOptions} valueOfSelected={defaultTargeting} onChange={value => setDefaultTargeting(value)} />
              </EuiFlexItem>
            </EuiFormRow>
            <EuiSpacer />
            <EuiFlexGroup gutterSize='s'>
              <EuiFlexItem grow={false}>
                <EuiButton isLoading={props.isSubmitting} fill type='submit'>
                  Save
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={true} />
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiFlexGroup gutterSize='s'>
              <EuiFlexItem grow={false}>
                <EuiButton isLoading={uploadOrganizationPipedriveQuery.isLoading} color={'primary'} onClick={onUploadOrganizationToPipedriveClick}>
                  Upload To Pipedrive
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={true} />
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiCopy textToCopy={`${config.DOTCOMURL}setup/?organizationId=${organization.organizationId}`}>
              {copy => (
                <EuiBadge color='default' onClick={copy} onClickAriaLabel='Copy Organization Card Setup URL'>
                  {`${config.DOTCOMURL}setup/?organizationId=${organization.organizationId}`}
                </EuiBadge>
              )}
            </EuiCopy>
          </EuiForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default OrganizationDetailsTab
