import { AdvertisingSnapshot } from 'api/entities/AdvertisingSnapshot'
import { Account, Organization } from 'api/index'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

const accountsTag = opsApi.enhanceEndpoints({ addTagTypes: ['Accounts', 'OrganizationAccounts', 'Account', 'AccountSnapshot'] })

const accountsApi = accountsTag.injectEndpoints({
  endpoints: build => ({
    getAccounts: build.query<Account[], { organizationId?: string }>({
      query: ({ organizationId }) => {
        return {
          url: `accounts`,
          params: { organizationId }
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (_result, _error, request) => [{ type: 'Accounts' }, { type: 'OrganizationAccounts', id: request.organizationId }]
    }),
    getAccount: build.query<Account, string | undefined>({
      query: id => `accounts/${id}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, id) => [{ type: 'Account', id }]
    }),
    getAdvertisingSnapshot: build.query<AdvertisingSnapshot, string>({
      query: id => `accounts/${id}/snapshot`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, id) => [{ type: 'AccountSnapshot', id }]
    })
  }),
  overrideExisting: false
})

export const { useGetAccountsQuery, useGetAccountQuery, useGetAdvertisingSnapshotQuery } = accountsApi
