import { useEffect, useState } from 'react'

import { EuiButton, EuiButtonIcon, EuiCallOut, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiRadio, EuiSpacer, EuiText, EuiTextArea } from '@elastic/eui'

import { useLazyGetPostalCodesCsvQuery } from 'api/rtkQueryApi/opsApi/targetingApi'
import { AcTargeterSelection } from 'components/ACTools/AcTargeterSelection'
import { AddressFinder } from 'components/Finders/AddressFinder'
import { CityFinderXandr } from 'components/Finders/CityFinderXandr'
import { DMAFinderXandr } from 'components/Finders/DMAFinderXandr'
import { PostalCodeFinderXandr } from 'components/Finders/PostalCodeFinderXandr'
import { RegionFinderXandr } from 'components/Finders/RegionFinderXandr'
import TargetingMap from 'components/TargetingMap'

import { City, Dma, LocationTargeting, GeoFence, PostalCode, GeoCircle, GeoRectangle, GeoPolygon, TargetingType } from '../../api/entities/Targeting'

export const LocationTargetingForm = ({ locationTargeting, onLocationTargetingChange, targetingPrioritization }: { locationTargeting: LocationTargeting; onLocationTargetingChange: (locationTargeting: LocationTargeting) => void; targetingPrioritization: TargetingType }) => {
  const [pastedZips, setPastedZips] = useState('')
  const [getZipsFromCsv, getZipsFromCsvQuery] = useLazyGetPostalCodesCsvQuery()
  const [zipsAsCsv, setZipsAsCsv] = useState('')
  const [showUsa, setShowUsa] = useState(targetingPrioritization === 'Audience')

  useEffect(() => {
    locationTargeting.postalCodes && setZipsAsCsv(locationTargeting.postalCodes?.map(r => r.code).join(','))
  }, [locationTargeting.postalCodes])

  const onPasteZipsClick = async () => {
    const zips = pastedZips.replaceAll(/\s/g, '')
    const zipResult = await getZipsFromCsv({ codeCsv: zips })

    setPastedZips('')
    onLocationTargetingChange({
      ...locationTargeting,
      postalCodes: locationTargeting.postalCodes ? locationTargeting.postalCodes?.concat(zipResult.data ?? []) : zipResult.data ?? []
    })
  }

  useEffect(() => {
    setShowUsa(targetingPrioritization === 'Audience')
    if (targetingPrioritization === 'Location' && locationTargeting.locationType === 'Country') {
      onLocationTargetingChange({ ...locationTargeting, locationType: 'Map' })
    }
  }, [targetingPrioritization, onLocationTargetingChange, locationTargeting])

  return (
    <>
      {showUsa && (
        <EuiRadio
          id='us'
          name='locationType'
          value='USA'
          label={
            <EuiText size='s'>
              <strong>Targeting Entire USA</strong>
            </EuiText>
          }
          checked={locationTargeting.locationType === 'Country'}
          onChange={() => {
            onLocationTargetingChange({ ...locationTargeting, locationType: 'Country' })
          }}
        />
      )}
      <EuiSpacer size='s' />
      <EuiRadio
        id='state'
        name='locationType'
        value='State'
        label={
          <EuiText size='s'>
            <strong>Targeting Using State</strong> (one or more U.S. States)
          </EuiText>
        }
        checked={locationTargeting.locationType === 'Region'}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: 'Region' })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='dma'
        name='locationType'
        value='DMA'
        label={
          <EuiText size='s'>
            <strong>Targeting Using DMA/Metro Area</strong> (one or more DMAs)
          </EuiText>
        }
        checked={locationTargeting.locationType === 'DMA'}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: 'DMA' })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='city'
        name='locationType'
        value='City'
        label={
          <EuiText size='s'>
            <strong>Targeting Using City</strong> (one or more City names)
          </EuiText>
        }
        checked={locationTargeting.locationType === 'City'}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: 'City' })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='address'
        name='locationType'
        value='Address'
        label={
          <EuiText size='s'>
            <strong>Target by Street Addresses</strong> (one or more)
          </EuiText>
        }
        checked={locationTargeting.locationType === 'Address'}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: 'Address' })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='zip'
        name='locationType'
        value='Zip'
        label={
          <EuiText size='s'>
            <strong>Targeting Using Zip Code</strong> (one or more Zip Codes)
          </EuiText>
        }
        checked={locationTargeting.locationType === 'Zip'}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: 'Zip' })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='map'
        name='locationType'
        value='Map'
        label={
          <EuiText size='s'>
            <strong>Target on Map</strong> (draw the areas you want to target on a map)
          </EuiText>
        }
        checked={locationTargeting.locationType === 'Map'}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: 'Map' })
        }}
      />
      <EuiSpacer size='s' />
      {locationTargeting.locationType === 'Region' && (
        <>
          <EuiFormRow fullWidth label={'Region'}>
            <RegionFinderXandr
              onOptionClick={region => {
                onLocationTargetingChange({ ...locationTargeting, regions: [...(locationTargeting.regions ?? []), region] })
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.regions?.map(r => ({ key: r.id.toString(), label: r.name ?? '' })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, regions: locationTargeting.regions?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === 'DMA' && (
        <>
          <EuiFormRow fullWidth label={'DMAs'}>
            <DMAFinderXandr onOptionClick={(dma: Dma) => onLocationTargetingChange({ ...locationTargeting, dmas: [...(locationTargeting.dmas ?? []), dma] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.dmas?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, dmas: locationTargeting.dmas?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === 'City' && (
        <>
          <EuiFormRow fullWidth label={'Cities'}>
            <CityFinderXandr onOptionClick={(city: City) => onLocationTargetingChange({ ...locationTargeting, cities: [...(locationTargeting.cities ?? []), city] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.cities?.map(r => ({ key: r.id.toString(), label: `${r.name}, ${r.regionCode}` })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, cities: locationTargeting.cities?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === 'Address' && (
        <>
          <EuiFormRow fullWidth label={'Addresses'}>
            <AddressFinder
              onOptionClick={(geoFence: GeoFence) =>
                onLocationTargetingChange({
                  ...locationTargeting,
                  geoFences: [...(locationTargeting.geoFences ?? []), geoFence]
                })
              }
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.geoFences?.map(r => ({ key: r.placeId.toString(), label: `${r.formattedAddress}` })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, geoFences: locationTargeting.geoFences?.filter(r => r.placeId.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === 'Zip' && (
        <>
          <EuiFormRow fullWidth label={'Zips'}>
            <PostalCodeFinderXandr onOptionClick={(zip: PostalCode) => onLocationTargetingChange({ ...locationTargeting, postalCodes: [...(locationTargeting.postalCodes ?? []), zip] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth label={'Paste Zips as CSV'}>
            <EuiTextArea placeholder='Paste zips as comma seperated list (ex:"12345,23456")' value={pastedZips} onChange={e => setPastedZips(e.target.value)} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton size='s' onClick={onPasteZipsClick} isLoading={getZipsFromCsvQuery.isLoading}>
                  {getZipsFromCsvQuery.isLoading ? 'Getting Zips Codes...' : 'Submit Zips'}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.postalCodes?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, postalCodes: locationTargeting.postalCodes?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiCopy textToCopy={zipsAsCsv}>{copy => <EuiButtonIcon title={'Copy as CSV'} iconType={'copy'} onClick={copy} />}</EuiCopy>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === 'Map' && (
        <>
          <EuiCallOut size='s' iconType='mapMarker' color='success' title='If you do not draw an area to target, the entire USA will be targeted by default.' />
          <EuiSpacer size='s' />
          {locationTargeting.locationType === 'Map' && (
            <TargetingMap
              geoCircles={locationTargeting.geoCircles ?? []}
              addCircle={(circle: GeoCircle) => {
                onLocationTargetingChange({ ...locationTargeting, geoCircles: [...(locationTargeting.geoCircles ?? []), circle] })
              }}
              removeCircle={(id: string) => {
                onLocationTargetingChange({ ...locationTargeting, geoCircles: locationTargeting.geoCircles?.filter(c => c.id !== id) ?? [] })
              }}
              modifyCircle={(circle: GeoCircle) => {
                onLocationTargetingChange({ ...locationTargeting, geoCircles: locationTargeting.geoCircles?.map(c => (c.id === circle.id ? circle : c)) })
              }}
              geoRectangles={locationTargeting.geoRectangles ?? []}
              addRectangle={(rectangle: GeoRectangle) => {
                onLocationTargetingChange({ ...locationTargeting, geoRectangles: [...(locationTargeting.geoRectangles ?? []), rectangle] })
              }}
              removeRectangle={(id: string) => {
                onLocationTargetingChange({ ...locationTargeting, geoRectangles: locationTargeting.geoRectangles?.filter(r => r.id !== id) ?? [] })
              }}
              modifyRectangle={(rectangle: GeoRectangle) => {
                onLocationTargetingChange({ ...locationTargeting, geoRectangles: locationTargeting.geoRectangles?.map(r => (r.id === rectangle.id ? rectangle : r)) })
              }}
              geoPolygons={locationTargeting.geoPolygons ?? []}
              addPolygon={(polygon: GeoPolygon) => {
                onLocationTargetingChange({ ...locationTargeting, geoPolygons: [...(locationTargeting.geoPolygons ?? []), polygon] })
              }}
              removePolygon={(id: string) => {
                onLocationTargetingChange({ ...locationTargeting, geoPolygons: locationTargeting.geoPolygons?.filter(p => p.id !== id) ?? [] })
              }}
              modifyPolygon={(polygon: GeoPolygon) => {
                onLocationTargetingChange({ ...locationTargeting, geoPolygons: locationTargeting.geoPolygons?.map(p => (p.id === polygon.id ? polygon : p)) ?? [] })
              }}
            />
          )}
        </>
      )}
    </>
  )
}
