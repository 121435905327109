import { useState } from 'react'

import { EuiFormRow, EuiSpacer, EuiSwitch, EuiTitle } from '@elastic/eui'

import { BaseTargeting, DayPartitioning } from '../../api/entities/Targeting'
import { DayPartPicker } from './DayPartPicker'

type BillboardTargetingFormProps = {
  billboardTargeting: BaseTargeting
  onBillboardTargetingChanged: (billboardTargeting: BaseTargeting) => void
}

export const BillboardTargetingForm = ({ billboardTargeting, onBillboardTargetingChanged }: BillboardTargetingFormProps) => {
  const [editCampaignDelivery, setEditCampaignDelivery] = useState(false)

  const onDayPartsChange = (dayParts: DayPartitioning) => {
    onBillboardTargetingChanged({
      ...billboardTargeting,
      dayParts
    })
  }

  return (
    <>
      <EuiSwitch label={'Edit Campaign Delivery Strategy'} checked={editCampaignDelivery} onChange={() => setEditCampaignDelivery(!editCampaignDelivery)} />
      <EuiSpacer />
      {editCampaignDelivery && (
        <>
          <EuiTitle size='s'>
            <h3>Campaign Delivery Strategy</h3>
          </EuiTitle>
          <EuiSpacer />
          <EuiFormRow label='Day Partitioning'>
            <DayPartPicker onSelectedDayPartsChange={onDayPartsChange} dayParts={billboardTargeting.dayParts} cellWidth={24} />
          </EuiFormRow>
        </>
      )}
    </>
  )
}
