import React from 'react'

import { EuiBadge, EuiBasicTableColumn, EuiInMemoryTable, SortDirection } from '@elastic/eui'

import { Account } from '../../api'
import { Targeting } from '../../api/entities/Targeting'
import { utcDate } from '../../utils/dateUtils'

type TargetingTableProps = {
  targeting?: Targeting[]
  accounts?: Account[]
  onEditClick?: (targeting: Targeting) => void
  onDeleteClicked?: (targeting: Targeting) => void
  loading?: boolean
  organizationDefaultTargetingId?: string
}
export const TargetingTable = ({ targeting, onEditClick, onDeleteClicked, loading, organizationDefaultTargetingId, accounts }: TargetingTableProps) => {
  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: true,
    sort: {
      field: 'orgId',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 25,
    pageSizeOptions: [10, 25, 50, 0]
  }

  const search = {
    box: {
      incremental: true
    }
  }

  const columns: Array<EuiBasicTableColumn<Targeting>> = [
    {
      field: 'name',
      name: 'Targeting Template',
      render: (name: Targeting['name']) => <strong>{name}</strong>,
      sortable: (item: Targeting) => item.name.toLowerCase()
    },
    {
      render: (targeting: Targeting) => {
        return targeting.id === organizationDefaultTargetingId ? <EuiBadge color='success'>Organization Default</EuiBadge> : null
      }
    },
    {
      field: 'locationTargeting.locationType',
      name: 'Location Targeting',
      render: (locationType, _record) => locationType,
      sortable: (targeting: Targeting) => targeting.locationTargeting?.locationType?.toLowerCase()
    },
    {
      name: 'Accounts Using Targeting',
      render: (targeting: Targeting) => {
        const accountsUsingTargeting = accounts?.filter(account => account.defaultTargetingId === targeting.id)

        return accountsUsingTargeting?.length
      }
    },
    {
      field: 'created',
      name: 'Created Date',
      render: (created: Targeting['created']) => (created ? utcDate(created) : undefined),
      sortable: targeting => (targeting.created ? utcDate(targeting.created) : undefined)
    },
    {
      name: 'Actions',
      actions: [
        {
          name: 'Edit',
          description: 'Edit this targeting item',
          icon: 'pencil',
          type: 'icon',
          onClick: item => {
            onEditClick?.(item)
          },
          available: _item => !loading
        },
        {
          name: 'Delete',
          description: 'Delete this targeting item',
          icon: 'trash',
          type: 'icon',
          color: 'danger',
          onClick: item => {
            onDeleteClicked?.(item)
          },
          available: _item => !loading
        }
      ]
    }
  ]

  return <EuiInMemoryTable search={search} pagination={pagination} sorting={sorting} loading={loading} tableCaption='Demo of EuiBasicTable' items={targeting ?? []} rowHeader='name' columns={columns} />
}
