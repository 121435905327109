import React, { FC, useState } from 'react'

import { EuiText } from '@elastic/eui'

export interface IDayPartPickerCell {
  isSelected: boolean
  onSelected?: () => void
  onMouseEnter?: () => void
  text?: string
  disableHover?: boolean
  cellWidth: number
  cellMargin: number
}

const dayPartCellBorder = '1px solid #d7d7d7'

const dayPartSelectedHoveredColor = '#6092C0'
const dayPartSelectedColor = '#006BB4'
const dayPartHoveredColor = '#98A2B3'
const dayPartBaseColor = 'white'

const getBackgroundColorForDayPart = (cell: Pick<IDayPartPickerCell, 'isSelected' | 'disableHover'>, hovered: boolean) => {
  if (cell) {
    if (cell.isSelected) {
      if (!cell.disableHover && hovered) {
        return dayPartSelectedHoveredColor
      } else {
        return dayPartSelectedColor
      }
    } else if (!cell.disableHover && hovered) {
      return dayPartHoveredColor
    }
  }
  return dayPartBaseColor
}

export const DayPartPickerCell: FC<IDayPartPickerCell> = ({ isSelected, onSelected, onMouseEnter, text, disableHover, cellWidth, cellMargin }) => {
  const [isHovered, setIsHovered] = useState(false)

  const onClick = () => {
    if (onSelected) {
      onSelected()
    }
  }

  const onMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <div
      style={{
        float: 'left',
        width: cellWidth + 'px',
        height: cellWidth + 'px',
        margin: cellMargin + 'px',
        border: dayPartCellBorder,
        borderRadius: '2px',
        textAlign: 'center',
        lineHeight: cellWidth - 4 + 'px',
        backgroundColor: getBackgroundColorForDayPart(
          {
            isSelected,
            disableHover
          },
          isHovered
        ),
        userSelect: 'none',
        msUserSelect: 'none'
      }}
      onMouseEnter={() => {
        setIsHovered(true)
        if (onMouseEnter) {
          onMouseEnter()
        }
      }}
      onMouseLeave={onMouseLeave}
      onClick={onClick}>
      <EuiText size='xs' draggable={false}>
        {text}
      </EuiText>
    </div>
  )
}
