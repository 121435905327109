import { AudienceTargeting, BaseTargeting, InternetTargeting, LocationTargeting, Targeting, TargetingType, TvTargeting } from '../../entities/Targeting'
import { defaultTransformFn, opsApi } from './opsApi'

const defaultTargetingTag = opsApi.enhanceEndpoints({ addTagTypes: ['DefaultTargeting', 'OrganizationDefaultTargeting'] })

type UpdateTargetingRequest = {
  id: string
  organizationId: string
  body: {
    name: string
    accountId?: string
    targetingPriority: TargetingType
    locationTargeting: LocationTargeting
    audienceTargeting: AudienceTargeting
    tvTargeting: TvTargeting
    billboardTargeting: BaseTargeting
    internetTargeting: InternetTargeting
  }
  username: string
}

type CreateTargetingRequest = {
  organizationId: string
  targeting: {
    name: string
    accountId?: string
    targetingPriority: TargetingType
    locationTargeting: LocationTargeting
    audienceTargeting: AudienceTargeting
    tvTargeting: TvTargeting
    billboardTargeting: BaseTargeting
    internetTargeting: InternetTargeting
  }
  username: string
}

const defaultTargetingApi = defaultTargetingTag.injectEndpoints({
  endpoints: build => ({
    getOrganizationDefaultTargeting: build.query<Targeting[], { organizationId: string }>({
      query: ({ organizationId }) => ({
        url: `defaultTargeting/getOrganizationDefaultTargeting/${organizationId}`,
        method: 'GET'
      }),
      transformResponse: defaultTransformFn,
      providesTags: (_result, _error, request) => [{ type: 'OrganizationDefaultTargeting', id: request.organizationId }]
    }),
    updateDefaultTargeting: build.mutation<Targeting, UpdateTargetingRequest>({
      query: ({ id, body, username }) => {
        return {
          url: `defaultTargeting/${id}/update`,
          method: 'POST',
          body: { ...body, username }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (_result, _error, { id, organizationId }) => [
        { type: 'OrganizationDefaultTargeting', id: organizationId },
        { type: 'DefaultTargeting', id }
      ]
    }),
    getDefaultTargetingById: build.query<Targeting, { id: string }>({
      query: request => {
        return {
          url: `defaultTargeting/${request.id}`,
          method: 'GET'
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (_result, _error, request) => [{ type: 'DefaultTargeting', id: request.id }]
    }),
    createDefaultTargeting: build.mutation<Targeting, CreateTargetingRequest>({
      query: request => {
        return {
          url: `defaultTargeting`,
          method: 'POST',
          body: { organizationId: request.organizationId, ...request.targeting, username: request.username }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (_result, _error, request) => [{ type: 'OrganizationDefaultTargeting', id: request.organizationId }]
    }),
    deleteDefaultTargeting: build.mutation<void, { id: string; orgId: string }>({
      query: ({ id }) => {
        return {
          url: `defaultTargeting/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: (_result, _error, { id, orgId }) => [
        { type: 'DefaultTargeting', id },
        { type: 'OrganizationDefaultTargeting', id: orgId }
      ]
    })
  }),
  overrideExisting: false
})

export const { useGetOrganizationDefaultTargetingQuery, useUpdateDefaultTargetingMutation, useGetDefaultTargetingByIdQuery, useLazyGetDefaultTargetingByIdQuery, useCreateDefaultTargetingMutation, useDeleteDefaultTargetingMutation } = defaultTargetingApi
