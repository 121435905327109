import { CampaignType } from '../../api'
import { Segment } from '../../api/entities/Targeting'
import { SegmentListBuilder } from '../Segments/SegmentListBuilder'

type SegmentsFormProps = {
  campaignType: CampaignType
  segments: Segment[]
  onSegmentsChange: (segments: Segment[]) => void
}

export const SegmentsForm = ({ campaignType, segments, onSegmentsChange }: SegmentsFormProps) => {
  return (
    <SegmentListBuilder
      segments={segments}
      campaignType={campaignType === CampaignType.TV ? CampaignType.TV : CampaignType.Internet}
      onSegmentAdded={segment => {
        onSegmentsChange([...segments, segment])
      }}
      onSegmentRemoved={segment => {
        onSegmentsChange(segments.filter(s => s.id !== segment.id))
      }}
    />
  )
}
