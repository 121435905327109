import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiBadge, EuiButton, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { Organization } from '../../../api'
import { Targeting } from '../../../api/entities/Targeting'
import { useGetAccountsQuery } from '../../../api/rtkQueryApi/opsApi/accountsApi'
import { useCreateDefaultTargetingMutation, useDeleteDefaultTargetingMutation, useGetOrganizationDefaultTargetingQuery, useUpdateDefaultTargetingMutation } from '../../../api/rtkQueryApi/opsApi/defaultTargetingApi'
import { RootState } from '../../../app/rootReducer'
import { TargetingFields, TargetingForm } from '../../../components/TargetingForm/TargetingForm'
import { TargetingTable } from '../../../components/TargetingTable/TargetingTable'
import { DEFAULT_TARGETING_FIELDS } from '../../../utils/TargetingFormDefaults'

export const OrganizationTargetingTab = ({ organization }: { organization: Organization }) => {
  const { organizationId: id, defaultTargetingId } = organization
  const targeting = useGetOrganizationDefaultTargetingQuery({ organizationId: id })
  const accounts = useGetAccountsQuery({ organizationId: id })
  const [deleteTargeting, deleteTargetingResult] = useDeleteDefaultTargetingMutation()
  const [createTargetingMutation, createTargetingResult] = useCreateDefaultTargetingMutation()
  const [updateTargetingMutation, updateTargetingResult] = useUpdateDefaultTargetingMutation()

  const [targetingId, setTargetingId] = useState<string | undefined>()
  const [targetingFields, setTargetingFields] = useState<TargetingFields>(DEFAULT_TARGETING_FIELDS)
  const [showDeleteTargetingModal, setShowDeleteTargetingModal] = useState(false)
  const [showCannotDeleteDefaultTargetingModal, setShowCannotDeleteDefaultTargetingModal] = useState(false)
  const [showTargetingFormFlyout, setShowTargetingFormFlyout] = useState(false)
  const [submitType, setSubmitType] = useState<'create' | 'update'>('create')
  const [saved, setSaved] = useState(true)

  const { userProfile } = useSelector((state: RootState) => state.app)

  const onTargetingUpdated = (updatedFields: TargetingFields) => {
    setTargetingFields(updatedFields)
    setSaved(false)
  }

  const onEditClick = async (targeting: Targeting) => {
    setTargetingId(targeting.id)
    setTargetingFields(targeting)
    setShowTargetingFormFlyout(true)
    setSubmitType('update')
  }

  const onDeleteClicked = (targeting: Targeting) => {
    if (targeting.id !== defaultTargetingId && accounts.data && accounts.data.filter(account => account.defaultTargetingId === targeting.id).length === 0) {
      setTargetingId(targeting.id)
      setShowDeleteTargetingModal(true)
    } else {
      setShowCannotDeleteDefaultTargetingModal(true)
    }
  }

  const onCreateTargetingClicked = () => {
    setShowTargetingFormFlyout(true)
    setSubmitType('create')
    setTargetingFields(DEFAULT_TARGETING_FIELDS)
  }

  const closeModal = () => {
    setShowTargetingFormFlyout(false)
    setTargetingId(undefined)
    setSaved(true)
  }

  const createTargeting = async () => {
    try {
      await createTargetingMutation({
        organizationId: id,
        targeting: targetingFields,
        username: userProfile?.username ?? ''
      })
      closeModal()
    } catch (error) {
      console.error('ERROR SAVING TARGETING', error)
    }
  }

  const updateTargeting = async () => {
    if (!targetingId) {
      return
    }
    try {
      await updateTargetingMutation({
        id: targetingId,
        organizationId: id,
        body: {
          ...targetingFields
        },
        username: userProfile?.username ?? ''
      })
      closeModal()
    } catch (error) {
      console.error('ERROR SAVING TARGETING', error)
    }
  }

  return (
    <>
      <EuiFlexGroup direction={'row'} justifyContent={'flexEnd'}>
        <EuiFlexItem>
          <EuiTitle>
            <h2>All Targeting</h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexGroup justifyContent={'flexEnd'}>
          <EuiFlexItem grow={false}>
            <EuiButton iconSide={'left'} iconType={'plus'} onClick={() => onCreateTargetingClicked()}>
              Targeting
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexGroup>
      <EuiSpacer size={'s'} />
      <TargetingTable targeting={targeting.data} organizationDefaultTargetingId={defaultTargetingId} loading={targeting.isFetching || accounts.isFetching} onEditClick={onEditClick} onDeleteClicked={onDeleteClicked} accounts={accounts.data} />
      <EuiSpacer size={'s'} />
      {showTargetingFormFlyout && (
        <EuiFlyout onClose={closeModal} outsideClickCloses={false}>
          <EuiFlyoutHeader>
            <EuiText>
              <h2>Edit Targeting</h2>
              {targetingId && (
                <EuiCopy textToCopy={targetingId}>
                  {copy => (
                    <EuiBadge color='default' onClick={copy} onClickAriaLabel='Copy Organization ID'>
                      {targetingId}
                    </EuiBadge>
                  )}
                </EuiCopy>
              )}
            </EuiText>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>{targetingFields && <TargetingForm onTargetingUpdated={onTargetingUpdated} targeting={targetingFields} />}</EuiFlyoutBody>
          <EuiFlyoutFooter>
            <EuiSpacer size={'s'} />
            <EuiFlexGroup justifyContent={'flexEnd'}>
              <EuiFlexItem grow={false}>
                <EuiButton type='submit' fill disabled={saved} onClick={submitType === 'create' ? createTargeting : updateTargeting} isLoading={createTargetingResult.isLoading || updateTargetingResult.isLoading}>
                  Save Targeting
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlyoutFooter>
        </EuiFlyout>
      )}
      {showDeleteTargetingModal && targetingId && (
        <EuiModal onClose={() => setShowDeleteTargetingModal(false)}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Delete Targeting?</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>You will permanently delete this targeting.</EuiModalBody>
          <EuiModalFooter>
            <EuiButton
              color={'danger'}
              onClick={async () => {
                try {
                  await deleteTargeting({ id: targetingId, orgId: id })
                  setShowDeleteTargetingModal(false)
                  setTargetingId(undefined)
                } catch (error) {
                  console.error(`Error deleting default targeting with id ${targetingId}`, error)
                }
              }}
              isLoading={deleteTargetingResult.isLoading}>
              Delete
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
      {showCannotDeleteDefaultTargetingModal && (
        <EuiModal
          onClose={() => {
            setShowCannotDeleteDefaultTargetingModal(false)
          }}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Cannot Delete Targeting</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>Please select another targeting as the default for the account or organization before deleting this targeting.</EuiModalBody>
        </EuiModal>
      )}
    </>
  )
}
